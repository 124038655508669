
// Function for returning the form inputs decided. Takes in user_edit dictionary and generated_result dictionary
export function newTotalInputs(user_edit_dictionary, generated_dictionary) {

    return {
        ...generated_dictionary,
        ...user_edit_dictionary
    }

}

export function giveBackUserViewVisit(visit) {
    const newVisit = {}
    const sections = ['call_info', 'treatment_info', 'vitals_info'];

    for (let section of sections) {
        newVisit[section] = {
            ...visit[section].generated_result.value,
            ...visit[section].user_edit.value,
        }
    }

    return newVisit

}